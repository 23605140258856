import { createContext, useContext, useState } from "react";

const DamagesDataContext = createContext();

function DamagesDataContextProvider({ children }) {
  const [damagesData, setDamagesData] = useState(false);

  const value = { damagesData, setDamagesData };
  return (
    <DamagesDataContext.Provider value={value}>
      {children}
    </DamagesDataContext.Provider>
  );
}

function useDamagesData() {
  const context = useContext(DamagesDataContext);
  if (context === undefined) {
    throw new Error(
      "useDamagesData must be used within a damagesDataContextProvider"
    );
  }
  return context;
}

export { DamagesDataContextProvider, useDamagesData };
