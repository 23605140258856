import companyNames from "../constants/companyNames.json";

export const getCompanyName = (domain) => {
  return companyNames[domain];
};

export const getDomain = (req) => {
  try {
    if (req.headers.host.includes("3000")) {
      return process.env.DEFAULT_BASE;
    } else {
      const url = req.headers.host.split(".");
      if (url[0] === "beta" || url[0] === "dev") {
        return url[2];
      } else if (url[0] === "app") {
        return url[1];
      } else {
        if (url[3] === undefined) throw new Error("undefined");
        return url[3];
      }
    }
  } catch (error) {
    return process.env.DEFAULT_BASE;
  }
};

export const getApiDomain = (req) => {
  try {
    if (req.headers.host.includes("3000")) {
      // return `https://beta.api.${process.env.DEFAULT_BASE}.customercloud.nl/v1`;
      return `https://api.${process.env.DEFAULT_BASE}.fleetdirect.dev/v1`;
    } else {
      const url = req.headers.host.split(".");
      if (url[0] === "beta") {
        return `https://beta.api.${url[2]}.${url[3]}.nl/v1`;
      } else if (url[0] === "app") {
        if (url[3].split("/")[0] === "dev") {
          return `https://api.${url[1]}.${url[2]}.dev/v1`;
        } else if (url[2] === "cclouddev") {
          return `https://beta.api.${url[2]}.customercloud.nl/v1`;
        } else return `https://api.${url[1]}.${url[2]}.nl/v1`;
      } else {
        if (url[3] === undefined) throw new Error("undefined");
        return url[3];
      }
    }
  } catch (error) {
    return `https://api.${process.env.DEFAULT_BASE}.fleetdirect.dev/v1`;
  }
};

export const getApiHeaders = (domain, token, uid) => {
  if (
    domain === "https://beta.api.vanmossel.customercloud.nl/v1" ||
    domain === "https://api.vanmossel.customercloud.nl/v1"
  ) {
    return {
      auth: {
        username: process.env.API_USERNAME,
        password: process.env.API_PASSWORD,
      },
    };
  } else {
    if (token) {
      return {
        headers: { Authorization: "Bearer " + token },
      };
    } else if (uid) {
      return { headers: uid };
    }
  }
};

export const getPasswordResetLink = (req) => {
  try {
    if (req.headers.host.includes("3000")) {
      // return `https://beta.api.${process.env.DEFAULT_BASE}.customercloud.nl/v1`;
      return `https://admin.${process.env.DEFAULT_BASE}.fleetdirect.dev/forgot`;
    } else {
      const url = req.headers.host.split(".");
      if (url[0] === "beta") {
        return `https://beta.admin.${url[2]}.${url[3]}.nl/forgot`;
      } else if (url[0] === "app") {
        if (url[3].split("/")[0] === "dev") {
          return `https://admin.${url[1]}.${url[2]}.dev/forgot`;
        } else if (url[2] === "cclouddev") {
          return `https://beta.admin.${url[2]}.customercloud.nl/forgot`;
        } else return `https://admin.${url[1]}.${url[2]}.nl/forgot`;
      } else {
        if (url[3] === undefined) throw new Error("undefined");
        return url[3];
      }
    }
  } catch (error) {
    return `https://api.${process.env.DEFAULT_BASE}.fleetdirect.dev/v1`;
  }
};
