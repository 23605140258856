import Head from "next/head";
import { useEffect } from "react";
import { Detector } from "react-detect-offline";
import Alert from "../components/layout/Alert";
import { getCompanyName, getDomain } from "../lib/helpers/getDomain";
import { hotjar } from "react-hotjar";
import "react-toastify/dist/ReactToastify.css";
import { DamagesDataContextProvider } from "../lib/context/damagesDataContext";

function MyApp({ Component, pageProps, domain }) {
  // useEffect(() => {
  //   hotjar.initialize(3180485, 6);
  // }, []);
  return (
    <>
      <Head>
        <meta
          property="og:title"
          content={`${domain} - FleetDirect`}
          key="title"
        />
        <title>{getCompanyName(domain)} - FleetDirect</title>
        <link href={`/dist/${domain}/app.bundle.css`} rel="stylesheet" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css"
        />
        <link rel="shortcut icon" href={`/${domain}/favicon.png`}></link>
      </Head>
      {/* <Detector
        render={({ online }) => (
          <Alert isVisible={!online}>Je bent offline!</Alert>
        )}
      /> */}
      <DamagesDataContextProvider>
        <Component {...pageProps} />
      </DamagesDataContextProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  const domain = await getDomain(ctx.req);

  return { domain };
};

export default MyApp;
