var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
  const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://b59ef91c01674a508b38d3f2a424951d@o1143569.ingest.sentry.io/6204323",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE
      ? process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE
      : 0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ["log", "info", "warn", "error", "debug", "assert"],
      }),
      new Sentry.Replay(),
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
